<template>
  <div class="circular-wrapper">
    <div id="circularChart"></div>
  </div>
</template>
<script>
//  
export default {
  props: {
    pieList: {
      type: Array,
      default: function () {
        return [0, 0, 0, 0, 0, 0, 0, 0, 0]
      }
    }
  },
  data () {
    return {
      totalNum: 0,
      nameList: [],
      dataList: [],
      tipTitle: '支付渠道统计'
    }
  },
  watch: {
    pieList: {
      handler (value) {
        this.totalNum = 0;
        this.dataList = [];
        this.nameList = [];
        for (var i = 0; i < value.length; i++) {
          this.totalNum += value[i].totalPayMoney * 1
          this.nameList.push(value[i].payChannelDictName)
          this.dataList.push({ name: value[i].payChannelDictName, value: Number((value[i].totalPayMoney) / 100).toFixed(2) })
        }
        if (this.totalNum > 0) {
          this.totalNum = (this.totalNum / 100).toFixed(2)
        }
        this.drawGraph();
      },
      deep: true,
      immediate: true
    }
  },
  methods: {
    drawGraph () {
      let circularChart;
      // let circularChart = this.$echarts.init(document.getElementById('circularChart'));
      let option = {
        color: ['#A6B7D4', '#527BFF', '#77D8FF', '#69FFC6', '#FFF1A2', '#FF92AE', '#F7936F', '#FFD14D', '#E1E1E1'],
        title: {
          text: '总收入（元）',
          subtext: this.totalNum + '',
          x: 'center',
          y: '130',
          itemGap: 10,
          textStyle: {
            color: '#27272E',
            fontSize: 14,
            fontWeight: 'normal'
          },
          subtextStyle: {
            color: '#27272E',
            fontWeight: 'bold',
            fontSize: 20
          }
        },
        tooltip: {
          trigger: 'item',
          formatter: function(val) {
            return val.marker + val.name + '：' + (val.percent + '%');
          }
          // formatter: "{a} <br/>{b}: {c} ({d}%)"
        },
        legend: {
          type: 'scroll',
          // orient: 'vertical',
          icon: "circle",
          itemWidth: 8,
          itemHeight: 8,
          bottom: 15,
          data: this.nameList
        },
        series: [
          {
            name: this.tipTitle,
            type: 'pie',
            radius: ['40%', '60%'],
            center: ['50%', '50%'],
            data: this.dataList,
            label: {
              formatter: '{b}: {d}%'
            },
            labelLine: {
              show: true,
              // 引导提示线第一段长度
              length: 10
            }
          }
        ]
      };
      if (document.getElementById('circularChart')) {
        circularChart = this.$echarts.init(document.getElementById('circularChart'));
        circularChart.setOption(option);
        window.addEventListener('resize', () => {
          circularChart.resize()
        })
      }
    }
  },
  mounted () {
    this.drawGraph();
  },
  created () {

  }
}
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.circular-wrapper
  position relative
  width 100%
  height 300px
  // overflow hidden
#circularChart
  width: 100%
  height: 300px
</style>
