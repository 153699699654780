<!--
 * @Author: faf
 * @desc: 
 * @Date: 2022-06-10 13:59:27
 * @LastEditors: faf
 * @ LastEditTime: 
-->
<template>
  <div class="circular-wrapper">
    <div id="circularChart"></div>
  </div>
</template>
<script>
//
export default {
  props: {
    barObj: {
      type: Object,
      default: function () {
        return {};
      }
    }
  },
  data() {
    return {
      totalNum: 0,
      incomeList: [],
      refundList: [],
      dataList: [],
      tipTitle: "支付渠道统计"
    };
  },
  watch: {
    barObj: {
      handler(value) {
        let that = this;
        that.dataList = [];
        that.incomeList = [];
        that.refundList = [];
        // ["停车", "停车卡", "预约停车", "预付", "异常支付", "重复支付"]
        if (
          value.temporaryParkPayMoney == "-" &&
          value.temporaryParkRefundMoney == "-"
        ) {
        } else {
          that.dataList.push("停车");
          value.temporaryParkPayMoney != "-" ? that.incomeList.push((Number(value.temporaryParkPayMoney) / 100).toFixed(2)) : that.incomeList.push("-");
          value.temporaryParkRefundMoney != "-" ? that.refundList.push((Number(value.temporaryParkRefundMoney) / 100).toFixed(2)) : that.refundList.push("-");
        }
        if (value.parkCardPayMoney == "-" &&
          value.parkCardRefundMoney == "-") {
        } else {
          that.dataList.push("停车卡");
          value.parkCardPayMoney != "-" ? that.incomeList.push((Number(value.parkCardPayMoney) / 100).toFixed(2)) : that.incomeList.push("-");
          value.parkCardRefundMoney != "-" ? that.refundList.push((Number(value.parkCardRefundMoney) / 100).toFixed(2)) : that.refundList.push("-");
          // that.refundList.push('0.00');
        }
        if (
          value.appointmentPayMoney == "-" &&
          value.appointmentRefundMoney == "-"
        ) {
        } else {
          that.dataList.push("预约停车");
          value.appointmentPayMoney != "-" ? that.incomeList.push((Number(value.appointmentPayMoney) / 100).toFixed(2)) : that.incomeList.push("-");
          value.appointmentRefundMoney != "-" ? that.refundList.push((Number(value.appointmentRefundMoney) / 100).toFixed(2)) : that.refundList.push("-");
        }
        if (value.staggeredPayMoney == "-") {
        } else {
          that.dataList.push("错峰停车");
          value.staggeredPayMoney != "-" ? that.incomeList.push((Number(value.staggeredPayMoney) / 100).toFixed(2)) : that.incomeList.push("-");
          that.refundList.push('0.00');
        }
        // if (value.prePayMoney == "-" && value.prePayRefundMoney == "-") {
        // } else {
        //   that.dataList.push("预付");
        //   that.incomeList.push(Number(value.prePayMoney) / 100);
        //   that.refundList.push(Number(value.prePayRefundMoney) / 100);
        // }
        if (value.exceptPayMoney == "-" && value.exceptPayRefundMoney == "-") {
        } else {
          that.dataList.push("异常支付");
          value.exceptPayMoney != "-" ? that.incomeList.push((Number(value.exceptPayMoney) / 100).toFixed(2)) : that.incomeList.push("-");
          value.exceptPayRefundMoney != "-" ? that.refundList.push((Number(value.exceptPayRefundMoney) / 100).toFixed(2)) : that.refundList.push("-");
        }
        if (value.repeatPayMoney == "-" && value.repeatPayRefundMoney == "-") {
        } else {
          that.dataList.push("重复支付");
          value.repeatPayMoney != "-" ? that.incomeList.push((Number(value.repeatPayMoney) / 100).toFixed(2)) : that.incomeList.push("-");
          value.repeatPayRefundMoney != "-" ? that.refundList.push((Number(value.repeatPayRefundMoney) / 100).toFixed(2)) : that.refundList.push("-");
        }
        that.drawGraph();
      },
      // deep: true,
      immediate: true
    }
  },
  methods: {
    drawGraph() {
      // let that = this;
      let circularChart = this.$echarts.init(
        document.getElementById("circularChart")
      );
      let option = {
        color: ["#527BFF", "#94E0FF"],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "line",
            lineStyle: {
              color: "#e1e1e1"
            }
          }
        },
        legend: {
          type: "scroll",
          icon: "circle",
          itemWidth: 8,
          itemHeight: 8,
          bottom: 5
        },
        grid: {
          left: "3%",
          right: "4%",
          top: "15%",
          containLabel: true
        },
        xAxis: [
          {
            type: "category",
            axisLine: {
              lineStyle: {
                type: "dashed",
                color: "#E4ECF7"
              }
            },
            axisLabel: {
              color: "#B0B7C3"
            },
            // ["停车", "停车卡", "预约停车", "预付", "异常支付", "重复支付"]
            data: this.dataList
          }
        ],
        yAxis: [
          {
            type: "value",
            axisLine: {
              show: false
            },
            // max: 40,
            splitNumber: 5,
            min: 0,
            // minInterval: 5,
            axisTick: {
              show: false
            },
            axisLabel: {
              color: "#B0B7C3"
            },
            splitLine: {
              type: "dotted",
              lineStyle: {
                type: "dashed",
                color: "#E4ECF7"
              }
            }
          }
        ],
        series: [
          {
            name: "收入",
            type: "bar",
            stack: "Ad",
            barWidth: 16,
            emphasis: {
              focus: "series"
            },
            itemStyle: {
              normal: {
                barBorderRadius: [2]
              }
            },
            data: this.incomeList || [0]
          },
          {
            name: "退款",
            type: "bar",
            stack: "Ad",
            barWidth: 16,
            emphasis: {
              focus: "series"
            },
            itemStyle: {
              normal: {
                barBorderRadius: [2]
              }
            },
            data: this.refundList
          }
        ]
      };
      circularChart.setOption(option, true);
      window.addEventListener("resize", () => {
        circularChart.resize();
      });
    }
  },
  mounted() {
    this.drawGraph();
  },
  created() {}
};
</script>
<style scoped lang="stylus" rel="stylesheet/stylus">
.circular-wrapper {
  width: 100%;
  height: 300px;
  overflow: hidden;
}

#circularChart {
  width: 100%;
  height: 290px;
}
</style>
