<template>
  <div class="page1">
    <div class="info" v-if="closeShow">
      <el-row>
        <el-col :span="24">
          <div class="info_content">
            <el-col :span="23">
              <span
                >渠道统计，统计支付日期内的各渠道各业务类型收入和退款情况，以环形图呈现渠道收入的占比情况，选中每个渠道可以查看该渠道的堆叠图；适用于运营和财务人员掌握各渠道收入和退款情况。</span
              >
            </el-col>
            <el-col :span="1">
              <img src="./img/close.png" @click="closeShow = false" alt="" />
            </el-col>
          </div>
        </el-col>
      </el-row>
    </div>
    <!--面包屑-->
    <!-- <div class="breadcrumb">
    </div> -->
    <!--主体内容-->
    <div class="content">
      <!--搜索条件区域-->
      <el-form
        :inline="true"
        status-icon
        label-position="right"
        label-width="70px"
        :model="formInline"
        ref="formInline"
        class="demo-form-inline bgFFF paddingT20 searchWrapper"
      >
        <el-form-item :label="$t('searchModule.Date_search')">
          <a-date-picker
            ref="datePicker"
            :YearShowHidden="selectkeys[3]"
            :selectkeys="selectkeys"
            :previousDate="previousDate"
            :nextDate="nextDate"
          ></a-date-picker>
        </el-form-item>
        <!-- <el-form-item :label="$t('searchModule.date')" prop="showDate" style="width: 200px">
          <el-select v-model.trim="formInline.showDate" filterable size="15" style="width: 100px">
            <el-option label="日" value="day">日</el-option>
            <el-option label="月" value="month">月</el-option>
          </el-select>
        </el-form-item>
        <el-form-item>
          <timeRangePick
            @timeChange="timeChange"
            ref="timeRangePicker"
            :type="formInline.showDate == 'day' ? 'daterange' : 'monthrange'"
            :valueFormat="formInline.showDate == 'day' ? 'yyyy-MM-dd' : 'yyyy-MM'"
          />
        </el-form-item> -->
        <div class="but">
          <el-button
            type="primary"
            icon="el-icon-search"
            @click="
              pageNum = 1;
              searchData();
            "
            :loading="loading"
            >查询
          </el-button>
        </div>
        <!-- <el-button type="primary" @click="pageNum=1;exportExcelReport()" :loading="loading">{{ $t('button.export') }}</el-button> -->
      </el-form>
      <div class="graphShadow marginTB20 paddingLR20">
        <CircularChart
          v-if="!isShowBar && !isShowPie"
          :pieList="pieList"
        ></CircularChart>
        <CircularBar v-if="isShowBar" :barObj="barObj"></CircularBar>
      </div>
      <!--列表区域-->
      <div class="tableWrapper">
        <!-- 筛选按钮 -->
        <div class="switch">
          <screen
            :checkBoxGroup="colData"
            :checkedColumns="checkedColumns"
            :reportType="6"
            :min="0"
            @selectChange="selectChange"
          ></screen>
        </div>
        <el-table
          :cell-style="callStyle"
          :key="reload"
          header-cell-class-name="headerCallStyle"
          border
          v-loading="loading"
          :data="tableData"
          style="width: 100%"
        >
          <el-table-column align="center">
            <!-- <div slot-scope="scope"><span>{{scope.row.alipayRefundMoney / 100}}</span></div> -->
            <template slot="header">
              <div class="table-color" @click="channelHandler(pieList, 1)">
                <label
                  class="table-color"
                  style="cursor: pointer"
                  @click="linkHandler($event)"
                  >渠道</label
                >
              </div>
            </template>
            <template slot-scope="scope">
              <div @click="channelHandler(scope, 2)">
                <label
                  class="table-color"
                  style="cursor: pointer"
                  @click="linkHandler($event)"
                  >{{ scope.row.payChannelDictName || "" }}</label
                >
              </div>
            </template>
          </el-table-column>
          <el-table-column align="center" min-width="95" label="收入合计">
            <template slot="header">
              <span class="vertical-middle">收入合计</span>
              <el-tooltip
                class="item"
                effect="dark"
                content="停车收入+停车卡订单实收+预约订单实收+错锋订单实收"
                placement="top-start"
              >
                <img src="./img/tip.png" alt="" />
              </el-tooltip>
            </template>
            <!-- totalPayMoney -->
            <div slot-scope="scope">
              <span>{{ scope.row.totalPayMoney | moneyHandling }}</span>
            </div>
          </el-table-column>
          <el-table-column
            align="center"
            v-if="colData[0].isTrue"
            label="退款合计"
            min-width="95"
          >
            <template slot="header">
              <span class="vertical-middle">退款合计</span>
              <el-tooltip
                class="item"
                effect="dark"
                content="停车退款+预约停车退款+异常支付退款+重复支付退款"
                placement="top-start"
              >
                <img src="./img/tip.png" alt="" />
              </el-tooltip>
            </template>
            <div slot-scope="scope">
              <span>{{ scope.row.totalRefundMoney | moneyHandling }}</span>
            </div>
          </el-table-column>
          <el-table-column
            align="center"
            v-if="colData[1].isTrue"
            label="停车收入"
            min-width="95"
          >
            <template slot="header">
              <span class="vertical-middle">停车收入</span>
              <el-tooltip
                class="item"
                effect="dark"
                content="按支付时间在统计日期的停车支付订单实付金额之和（含预付抵扣金额）"
                placement="top-start"
              >
                <img src="./img/tip.png" alt="" />
              </el-tooltip>
            </template>
            <div slot-scope="scope">
              <span>{{ scope.row.temporaryParkPayMoney | moneyHandling }}</span>
            </div>
          </el-table-column>
          <el-table-column
            align="center"
            v-if="colData[2].isTrue"
            label="停车退款"
            min-width="95"
          >
            <template slot="header">
              <span class="vertical-middle">停车退款</span>
              <el-tooltip
                class="item"
                effect="dark"
                content="按退款时间在统计日期停车记录中实付退款金额之和"
                placement="top-start"
              >
                <img src="./img/tip.png" alt="" />
              </el-tooltip>
            </template>
            <div slot-scope="scope">
              <span>{{
                scope.row.temporaryParkRefundMoney | moneyHandling
              }}</span>
            </div>
          </el-table-column>
          <el-table-column
            align="center"
            v-if="colData[3].isTrue"
            min-width="120px"
            label="停车卡收入"
          >
            <template slot="header">
              <span class="vertical-middle">停车卡收入</span>
              <el-tooltip
                class="item"
                effect="dark"
                content="按支付时间在统计日期的停车卡实付金额之和"
                placement="top-start"
              >
                <img src="./img/tip.png" alt="" />
              </el-tooltip>
            </template>
            <div slot-scope="scope">
              <span>{{ scope.row.parkCardPayMoney | moneyHandling }}</span>
            </div>
          </el-table-column>
          <el-table-column
            align="center"
            v-if="colData[4].isTrue"
            min-width="120px"
            label="停车卡退款"
          >
            <template slot="header">
              <span class="vertical-middle">停车卡退款</span>
              <el-tooltip
                class="item"
                effect="dark"
                content="按退款时间在统计日期的停车卡退款金额之和"
                placement="top-start"
              >
                <img src="./img/tip.png" alt="" />
              </el-tooltip>
            </template>
            <div slot-scope="scope">
              <span>{{ scope.row.parkCardRefundMoney | moneyHandling }}</span>
            </div>
          </el-table-column>
          <el-table-column
            align="center"
            v-if="colData[5].isTrue"
            min-width="124"
            label="预约停车收入"
          >
            <template slot="header">
              <span class="vertical-middle">预约停车收入</span>
              <el-tooltip
                class="item"
                effect="dark"
                content="按支付时间在统计日期的停车预约实付金额之和"
                placement="top-start"
              >
                <img src="./img/tip.png" alt="" />
              </el-tooltip>
            </template>
            <div slot-scope="scope">
              <span>{{ scope.row.appointmentPayMoney | moneyHandling }}</span>
            </div>
          </el-table-column>
          <el-table-column
            align="center"
            v-if="colData[6].isTrue"
            min-width="124"
            label="预约停车退款"
          >
            <template slot="header">
              <span class="vertical-middle">预约停车退款</span>
              <el-tooltip
                class="item"
                effect="dark"
                content="按退款时间在统计日期的预约停车退款金额之和"
                placement="top-start"
              >
                <img src="./img/tip.png" alt="" />
              </el-tooltip>
            </template>
            <div slot-scope="scope">
              <span>{{
                scope.row.appointmentRefundMoney | moneyHandling
              }}</span>
            </div>
          </el-table-column>
          <el-table-column
            align="center"
            v-if="colData[7].isTrue"
            min-width="124"
            label="错峰停车收入"
          >
            <template slot="header">
              <span class="vertical-middle">错峰停车收入</span>
              <el-tooltip
                class="item"
                effect="dark"
                content="按支付时间在统计日期的错锋订单实付金额之和"
                placement="top-start"
              >
                <img src="./img/tip.png" alt="" />
              </el-tooltip>
            </template>
            <div slot-scope="scope">
              <span>{{ scope.row.staggeredPayMoney | moneyHandling }}</span>
            </div>
          </el-table-column>
          <el-table-column
            align="center"
            v-if="colData[8].isTrue"
            label="异常支付"
            min-width="95"
          >
            <template slot="header">
              <span class="vertical-middle">异常支付</span>
              <el-tooltip
                class="item"
                effect="dark"
                content="按支付时间在统计日期的异常支付的支付金额之和"
                placement="top-start"
              >
                <img src="./img/tip.png" alt="" />
              </el-tooltip>
            </template>
            <div slot-scope="scope">
              <span>{{ scope.row.exceptPayMoney | moneyHandling }}</span>
            </div>
          </el-table-column>
          <el-table-column
            align="center"
            v-if="colData[9].isTrue"
            min-width="124"
            label="异常支付退款"
          >
            <template slot="header">
              <span class="vertical-middle">异常支付退款</span>
              <el-tooltip
                class="item"
                effect="dark"
                content="按退款时间在统计日期的异常支付的退款金额之和"
                placement="top-start"
              >
                <img src="./img/tip.png" alt="" />
              </el-tooltip>
            </template>
            <div slot-scope="scope">
              <span>{{ scope.row.exceptPayRefundMoney | moneyHandling }}</span>
            </div>
          </el-table-column>
          <el-table-column
            align="center"
            v-if="colData[10].isTrue"
            label="重复支付"
            min-width="95"
          >
            <template slot="header">
              <span class="vertical-middle">重复支付</span>
              <el-tooltip
                class="item"
                effect="dark"
                content="按支付时间在统计日期的重复支付的支付金额之和"
                placement="top-start"
              >
                <img src="./img/tip.png" alt="" />
              </el-tooltip>
            </template>
            <div slot-scope="scope">
              <span>{{ scope.row.repeatPayMoney | moneyHandling }}</span>
            </div>
          </el-table-column>
          <el-table-column
            align="center"
            v-if="colData[11].isTrue"
            min-width="180"
            style="margin-right: 50px"
            label="重复支付退款"
          >
            <template slot="header">
              <span class="vertical-middle">重复支付退款</span>
              <el-tooltip
                class="item"
                effect="dark"
                content="按退款时间在统计日期的重复支付的退款金额之和"
                placement="top-start"
              >
                <img src="./img/tip.png" alt="" />
              </el-tooltip>
            </template>
            <div slot-scope="scope">
              <span>{{ scope.row.repeatPayRefundMoney | moneyHandling }}</span>
            </div>
          </el-table-column>
        </el-table>
        <!--分页器-->
        <div class="pagerWrapper">
          <div class="block">
            <el-pagination
              v-if="total != 0"
              @current-change="handleCurrentChange"
              background
              :current-page="pageNum"
              :page-size="pageSize"
              layout="total, prev, pager, next, jumper"
              :total="total"
            >
            </el-pagination>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import CircularChart from "../../components/circularChart/circularChart6";
import CircularBar from "../../components/circularChart/circularBar";
import screen from "@/components/screen/index";

export default {
  name: "payChannelForm",
  data() {
    // const startTime = new Date();
    // startTime.setTime(startTime.getTime() - 31 * 24 * 60 * 60 * 1000);
    // const endTime = new Date();
    // endTime.setTime(endTime.getTime() - 3600 * 1000 * 24);
    return {
      // pickerOptions: {
      //   disabledDate (time) {
      //     return time.getTime() > endTime;
      //   }
      // },
      closeShow: true,
      screeningNum: 0,
      reload: true,
      isShowBar: false,
      isShowPie: false,
      // colData中列出表格中的每一列，默认都展示
      colData: [
        { label: "退款合计", isTrue: false },
        { label: "停车收入", isTrue: false },
        { label: "停车退款", isTrue: false },
        { label: "停车卡收入", isTrue: false },
        { label: "停车卡退款", isTrue: false },
        { label: "预约停车收入", isTrue: false },
        { label: "预约停车退款", isTrue: false },
        { label: "错峰停车收入", isTrue: false },
        { label: "异常支付", isTrue: false },
        { label: "异常支付退款", isTrue: false },
        { label: "重复支付", isTrue: false },
        { label: "重复支付退款", isTrue: false }
      ],
      // 当前选中的多选框，代表当前展示的列
      checkedColumns: [],
      isColCheckList: [],
      selectkeys: ["date", "month", "week", "year"],
      previousDate: new Date(),
      nextDate: new Date(),
      loading: false,
      pageNum: 1,
      pageSize: 15,
      total: 0,
      // value1: endTime,
      tableData: [],
      barObj: {},
      pieList: [0, 0, 0, 0, 0, 0, 0, 0],
      formInline: {
        showDate: "day",
        startTime: "",
        endTime: ""
      },
      channelTypeList: [],
      tableCols: [
        // {
        //   prop: "name",
        //   label: "渠道",
        // },
        // {
        //   prop: "value",
        //   label: "收入",
        //   formatter: (row) => {
        //     if (row.value) {
        //       return Number(row.value / 100).toFixed(2);
        //     } else {
        //       return "0.00";
        //     }
        //   },
        // },

        // {
        //   prop: "payChannelDictName",
        //   label: "渠道"
        // },
        // {
        //   prop: "totalPayMoney",
        //   label: "收入合计"
        // },
        {
          prop: "totalRefundMoney",
          label: "退款合计"
        },
        {
          prop: "temporaryParkPayMoney",
          label: "停车收入"
        },
        {
          prop: "temporaryParkRefundMoney",
          label: "停车退款"
        },
        {
          prop: "parkCardPayMoney",
          label: "停车卡收入"
        },
                {
          prop: "parkCardRefundMoney",
          label: "停车卡退款"
        },
        {
          prop: "appointmentPayMoney",
          label: "预约停车收入"
        },
        {
          prop: "appointmentRefundMoney",
          label: "预约停车退款"
        },
        {
          prop: "staggeredPayMoney",
          label: "错峰停车收入"
        },
        // {
        //   prop: "prePayMoney",
        //   label: this.$t("list.prepaid_amount")
        // },
        // {
        //   prop: "prePayRefundMoney",
        //   label: "预付退款"
        // },
        {
          prop: "exceptPayMoney",
          label: "异常支付"
        },
        {
          prop: "exceptPayRefundMoney",
          label: "异常支付退款"
        },
        {
          prop: "repeatPayMoney",
          label: "重复支付"
        },
        {
          prop: "repeatPayRefundMoney",
          label: "重复支付退款"
        }
      ]
    };
  },
  watch: {},
  methods: {
    selectChange(selectList) {
      this.colData.filter((i, index) => {
        i.isTrue = false;
      });
      selectList.forEach((i) => {
        this.colData[i].isTrue = true;
      });
      this.searchData();
    },
    getReportFieldHide() {
      this.$axios
        .get("/acb/2.0/reportFieldHide/get", {
          data: {
            reportType: 6,
          },
        })
        .then((res) => {
          if (res.value.length == 1 && res.value[0] == "") {
            this.checkedColumns = [];
          } else {
            this.checkedColumns = res.value.map(Number);
          }
          this.checkedColumns.forEach((i) => {
            this.colData[i].isTrue = true
          });
        });
    },
    // table 单元格样式动态设置
    callStyle(row) {
      return {
        "border-right": 0
      };
    },
    timeChange(timeArr) {
      this.formInline.startTime = timeArr[0];
      this.formInline.endTime = timeArr[1];
    },
    // getTimeNow () {
    //   const startTime = new Date();
    //   const endTime = new Date();
    //   if (this.formInline.showDate == "day") {
    //     startTime.setTime(startTime.getTime() - 31 * 24 * 60 * 60 * 1000);
    //     endTime.setTime(endTime.getTime() - 3600 * 1000 * 24);
    //   } else {
    //     startTime.setTime(startTime.getTime() - 365 * 24 * 3600 * 1000);
    //     endTime.setTime(endTime.getTime() - 31 * 24 * 3600 * 1000);
    //   }
    //   this.startTime = startTime;
    //   this.endTime = endTime;
    // },
    handleCurrentChange(val) {
      this.pageNum = val;
      this.searchData();
    },
    // 时间判断
    showLog() {
      if (this.formInline.startTime && this.formInline.endTime) {
        let time =
          new Date(this.formInline.endTime) -
          new Date(this.formInline.startTime);
        time = time / (24 * 60 * 60 * 1000);
        let diff = "";
        if (this.formInline.showDate === "day") {
          if (time >= 31) {
            this.$alert("查询时间不能大于31天");
            return false;
          } else {
            return true;
          }
        } else {
          if (time > 365) {
            this.$alert("查询时间不能大于12个月");
            return false;
          } else {
            return true;
          }
        }
      } else {
        this.$alert("日期不能为空", this.$t('pop_up.Tips'), {
          confirmButtonText: this.$t('pop_up.Determine')
        });
        return false;
      }
    },
    // 支付渠道统计
    getPayChannel() {
      this.$axios
        .get("/acb/2.0/financeStatistic/payChannel", {
          data: {
            dateType: this.formInline.showDate, // 必填，day/month
            startTime: this.formInline.startTime, // 必填，格式要求day:yyyy-MM-dd,month:yyyy-MM
            endTime: this.formInline.endTime // 必填，格式要求day:yyyy-MM-dd,month:yyyy-MM
          }
        })
        .then((res) => {
          if (res.value.ChannelInComeData) {
            let arr = JSON.parse(res.value.ChannelInComeData);
            this.channelTypeList.forEach((item) => {
              arr.forEach((e) => {
                if (item.code == Object.keys(e)[0]) {
                  e.name = item.desc;
                  e.value = e.actualPay;
                }
              });
            });
            console.log(JSON.parse(res.value.ChannelInComeData));
            // 筛除  银联数据
            this.tableData = arr;
          } else {
            this.tableData = [];
          }
          // this.total = res.value.total * 1 || 0;
        });
    },
    // 停车场收入报表 导出
    // exportExcelReport() {
    //   exportExcelNew('/acb/2.0/financeStatistic/payChannel/' + this.formInline.showDate + '/' + this.dateType(), this.formInline)
    // },
    searchData() {
      this.$axios
        .get("/acb/2.0/payChannelReport/list", {
          data: {
            reportType: this.$refs.datePicker
              ? this.$refs.datePicker.getDateType()
              : "", // 必填，day/month
            startDate: this.$refs.datePicker
              ? this.$refs.datePicker.getStartDate()
              : "", // 必填，格式要求day:yyyy-MM-dd,month:yyyy-MM
            endDate: this.$refs.datePicker
              ? this.$refs.datePicker.getEndDate()
              : "" // 必填，格式要求day:yyyy-MM-dd,month:yyyy-MM
          }
        })
        .then((res) => {
          if (res.state == 0) {
            this.tableData = res.value || [];
            this.pieList = res.value || [];
            // this.getReportFieldHide(this);
          } else {
            this.tableData = [];
            this.pieList = [];
            // this.barObj = [];
          }
        });
    },

    linkHandler(e) {
      e.target.style.color = "#f56c6c";
    },

    /**
     * 点击渠道
     */
    channelHandler(data, type) {
      if (type == 2) {
        data.row.isClick = true;
        let obj = this.tableData[data.$index];
        console.log('af', obj)
        this.isShowBar = true;
        this.isColCheckList.forEach((val) => {
          switch (val) {
            // case "停车收入":
            case 1:
              obj.temporaryParkPayMoney = "-";
              break;
            // case "停车退款":
            case 2:
              obj.temporaryParkRefundMoney = "-";
              break;
            // case "停车卡收入":
            case 3:
              obj.parkCardPayMoney = "-";
              break;
            // case "预约停车收入":
            case 4:
              obj.appointmentPayMoney = "-";
              break;
            // case "预约停车退款":
            case 5:
              obj.appointmentRefundMoney = "-";
              break;
            // case "错峰停车收入"
            case 6:
              obj.staggeredPayMoney = "-";
              break;
            // case "预付金额":
            // case 7:
            //   obj.prePayMoney = "-";
            //   break;
            // case "预付退款":
            // case 8:
            //   obj.prePayRefundMoney = "-";
            //   break;
            // case "异常支付":
            case 7:
              obj.exceptPayMoney = "-";
              break;
            // case "异常支付退款":
            case 8:
              obj.exceptPayRefundMoney = "-";
              break;
            // case "重复支付":
            case 9:
              obj.repeatPayMoney = "-";
              break;
            // case "重复支付退款":
            case 10:
              obj.repeatPayRefundMoney = "-";
              break;
             // case "停车卡退款":
            case 11:
              obj.parkCardRefundMoney = "-";
              break;
            default:
              break;
          }
        });
        this.barObj = obj;
      } else {
        this.pieList = this.tableData;
        this.isShowBar = false;
      }
      this.searchData();
    }
  },
  components: {
    CircularChart,
    CircularBar,
    screen
  },
  beforeDestroy() {},
  activated() {},
  created() {
    // this.searchData();
    // this.channelHandler(this.pieList, 1);
  },
  computed: {},
  mounted() {
    this.searchData();
    this.getReportFieldHide();
  }
};
</script>
<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="stylus" rel="stylesheet/stylus">
.breadcrumb {
  height: 35px;
}

.content {
  .but {
    float: right;
  }

  .list {
    margin-top: 20px;

    .el-col {
      padding-bottom: 18px;
      height: 320px;

      .grid-content {
        padding-top: 15px;
        height: 100%;
      }

      h2 {
        font-size: 18px;
        text-align: center;
      }
    }
  }

  .table-color {
    color: #0f6eff;
  }

  .clicked-color {
    color: red !important;
  }

  .vertical-middle {
    vertical-align: middle;
    padding-right: 4px;
  }
}

.table-actived {
  color: red;
}

.info {
  position: relative;
  left: -20px;
  width: calc(100% + 20px);
  height: 40px;
  padding-left: 20px;
  line-height: 40px;
  background: rgba(254, 242, 213, 0.47);

  .info_content {
    background: rgba(254, 242, 213, 0.47);
    color: rgba(255, 151, 74, 1);
  }

  img {
    width: 24px;
    height: 24px;
    vertical-align: middle;
    cursor: pointer;
  }
}

.tableWrapper {
  position: relative;

  /deep/ .el-table th.el-table__cell {

  }

  /deep/ .el-table th.el-table__cell:nth-child(1), /deep/ .el-table th.el-table__cell:nth-child(2),
  /deep/ .el-table td.el-table__cell:nth-child(1), /deep/ .el-table td.el-table__cell:nth-child(2) {
    background: #F0FDDC !important;
  }

  img {
    width: 14px;
    height: 14px;
    vertical-align: middle;
  }

  .switch {
    position: absolute;
    right: 0;
    z-index: 2;
    top: 1px;
    cursor: pointer;
    img {
      height: 54px;
      width: 40px;
    }
  }
}

.tableWrapper >>> .headerCallStyle {
  font-size: 14px;
  background: #F3F7FF !important;
  border-right: 0;
  font-weight: 600;
  color: rgba(0, 0, 0, 0.85);
}

.tableWrapper >>> .el-table {
  width: 100% !important;
}
</style>
